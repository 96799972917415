import { useEffect, useState, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import Spinner from "../components/Spinner";
import Moment from "react-moment";
import { FaShare } from "react-icons/fa";
import { Helmet } from "react-helmet";

export default function Blog() {
  const { blogId } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [shareLinkCopied, setShareLinkCopied] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const modalRef = useRef(null);

  useEffect(() => {
    const fetchBlog = async () => {
      const docRef = doc(db, "blogs", blogId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setBlog(docSnap.data());
        setLoading(false);
      }
    };
    fetchBlog();
  }, [blogId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setSelectedImage(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <main>
      <Helmet>
        <title>{blog.title}</title>
        <meta name="description" content={blog.content.substring(0, 150) + '...'} />
        <meta name="keywords" content="Creative designs, creative designer, websites, web development, mobile App development, Social media management, blockchain, Dev Royale, web3, smart contract Royale, Royale&#39;s portfolio, Ekeoba Unity,ekeoba. unity, ekeoba," />
        <meta name="author" content="Unity Ekeoba" />
        <meta property="og:image:height" content="1200" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={blog.imageUrl} />
        <meta name="twitter:card" content="Dev Royale is a creative web developer with other skills. Such as Social media management, creative designs, mobile app development and blockchain development as well." />

      </Helmet>

      {blog.imageUrl && (
        <div
          className="relative w-full overflow-hidden h-[300px] cursor-pointer"
          style={{
            background: `url(${blog.imageUrl}) center no-repeat`,
            backgroundSize: "cover",
          }}
          onClick={() => setSelectedImage(blog.imageUrl)}
        ></div>
      )}

      {selectedImage && (
        <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50" onClick={() => setSelectedImage(null)}>
          <div className="bg-white p-4 rounded-lg" ref={modalRef}>
            <img src={selectedImage} alt="Full size" className="max-w-full max-h-full" />
            <button className="absolute top-0 right-0" onClick={() => setSelectedImage(null)}>Close</button>
          </div>
        </div>
      )}

      <div
        className="fixed top-[13%] right-[3%] z-10 bg-white cursor-pointer border-2 border-gray-400 rounded-full w-12 h-12 flex justify-center items-center"
        onClick={() => {
          navigator.clipboard.writeText(window.location.href);
          setShareLinkCopied(true);
          setTimeout(() => {
            setShareLinkCopied(false);
          }, 2000);
        }}
      >
        <FaShare className="text-lg text-slate-500" />
      </div>

      {shareLinkCopied && (
        <p className="fixed top-[23%] right-[5%] font-semibold border-2 border-gray-400 rounded-md bg-white z-10 p-2">
          Link Copied
        </p>
      )}

      <div className="m-4 flex flex-col max-w-4xl lg:mx-auto p-4 rounded-lg shadow-lg bg-transparent">
        <div className="w-full">
          <h1 className="text-2xl font-bold mb-3 text-[#000]">{blog.title}</h1>
          <p className="text-sm text-gray-500 mb-3">
            <Moment fromNow>{blog.createdAt.toDate()}</Moment>
          </p>
          <div className="text-gray-800 mt-4 mb-6 whitespace-pre-wrap">
            {blog.content}
          </div>
          <div className="text-gray-500 mt-4 mb-6 flex flex-wrap">
            {blog.tags}
          </div>
          {/* <div className="flex items-center mb-4">
            <Link to={`/user-profile/${blog.userId}`} className="mr-4">
              <img
                src={blog.userProfilePicture}
                alt="Profile"
                className="w-12 h-12 object-cover rounded-full cursor-pointer"
              />
            </Link>
            <p className="text-lg font-semibold">{blog.userName}</p>
          </div> */}
        </div>
      </div>
    </main>
  );
}
