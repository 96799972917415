import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, addDoc, getDocs, query, where, doc, runTransaction } from "firebase/firestore";
import { toast } from "react-toastify";
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";
import CreateBlog from "../components/CreateBlog";

const MAX_ADMINS = 3;
const Admin = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);
    const [activeTab, setActiveTab] = useState("blogs");

    useEffect(() => {

        const checkAdmin = async (email) => {
            try {
                const adminQuery = query(collection(db, "admins"), where("email", "==", email));
                const adminSnapshot = await getDocs(adminQuery);
                if (!adminSnapshot.empty) {
                    setIsAdmin(true);
                    return true;
                }
                return false;
            } catch (error) {
                console.error("Error checking admin:", error);
                return false;
            }
        };
        const addAdmin = async (newAdminData) => {
            try {
                await runTransaction(db, async (transaction) => {
                    const adminsCollectionRef = collection(db, "admins");
                    const adminsSnapshot = await transaction.get(adminsCollectionRef);
                    const adminCount = adminsSnapshot.size;

                    if (adminCount >= MAX_ADMINS) {
                        throw new Error("Maximum number of admin documents reached");
                    }

                    const newAdminDocRef = doc(adminsCollectionRef);
                    transaction.set(newAdminDocRef, newAdminData);
                });

                toast.success("Admin added successfully");
            } catch (error) {
                console.error("Error adding admin:", error);
                if (error.message === "Maximum number of admin documents reached") {
                    toast.error("Maximum number of admin documents reached");
                } else {
                    toast.error("Error adding admin");
                }
            }
        };
        checkAdmin(email);
    }, [email]);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const adminQuery = query(collection(db, "admins"), where("email", "==", email));
            const adminSnapshot = await getDocs(adminQuery);
            if (adminSnapshot.empty) {
                const adminsCollectionRef = collection(db, "admins");
                const adminsSnapshot = await getDocs(adminsCollectionRef);
                if (adminsSnapshot.size >= MAX_ADMINS) {
                    toast.error("Maximum number of admin documents reached");
                    return;
                }

                await addDoc(adminsCollectionRef, {
                    email,
                    password,
                });
                toast.success("Admin account created successfully!");
            } else {
                await signInWithEmailAndPassword(getAuth(), email, password);
                toast.success("Logged in successfully!");
                setIsAdmin(true);
            }
        } catch (error) {
            console.error("Login error:", error);
            toast.error("Error logging in!");
        }
    };

    
    
    

    return (
        <div className="admin-page">
            <h1 className="text-center font-bold text-4xl my-5">Admin Page</h1>
            {!isAdmin && (
                <form onSubmit={handleLogin} className="flex flex-col md-down:mx-3 max-w-[768px] mx-auto gap-5">
                    <div className="flex flex-col">
                        <label htmlFor="email" className="mb-1">Email:</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="border p-2 rounded"
                        />
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="password" className="mb-1">Password:</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="border p-2 rounded"
                        />
                    </div>
                    <button type="submit" className="bg-[#323232] text-white py-2 px-4 rounded mt-4">Login</button>
                </form>
            )}

            {isAdmin && (
                <>
                    <div className="flex flex-wrap justify-center space-x-4 mb-5">
                        <button
                            onClick={() => setActiveTab("blogs")}
                            className={`py-2 mb-5 px-4 text-white ${activeTab === "blogs" ? "bg-[#E0D9D3]" : "bg-[#323232]"}`}
                        >
                            Write Blog
                        </button>
                    </div>

                    {activeTab === "blogs" && (
                        <CreateBlog />
                    )}
                </>
            )}
        </div>
    );
};

export default Admin;
