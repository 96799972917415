import { Link } from "react-router-dom";
// import Moment from "react-moment";
// import { MdLocationOn } from "react-icons/md";
import { FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";

const ListingItem = ({ listing, id, onEdit, onDelete }) => {
  return (
    <li className="relative border-4 border-[#323232]  text-black flex flex-col justify-between items-center shadow-md shadow-[#0000] hover:shadow-xl rounded-md overflow-hidden transition-shadow duration-150 m-[10px]"
    style={{
      background: "#fff"
    }}>
      <Link className="contents" to={`/portfolio/${id}`}>
        <img
          className="h-[170px] w-full object-cover hover:scale-110 transition-scale duration-200 ease-in"
          loading="lazy"
          src={listing.imgUrls[0]}
          alt=""
        />
        {/* <Moment
          className="absolute top-2 left-2 bg-blue-400 text-white uppercase text-xs font-semibold rounded-md px-2 py-1 shadow-lg"
          fromNow
        >
          {listing.timestamp?.toDate()}
        </Moment> */}
        <div className="w-full p-[10px]">
          <p className="font-semibold m-0 text-xl truncate">{listing.name}</p>
        </div>
      </Link>
      {onDelete && (
        <FaTrash
          className="absolute bottom-2 right-2 h-[14px] cursor-pointer text-red-500"
          onClick={() => onDelete(listing.id)}
        />
      )}
      {onEdit && (
        <MdEdit
          className="absolute bottom-2 right-7 h-4 cursor-pointer"
          onClick={() => onEdit(listing.id)}
        />
      )}
    </li>
  );
};

export default ListingItem;