import React, { useEffect, useRef } from "react";
import bg from "../assets/svg/Frame 1.png";
import {
    AiFillMail,
    AiFillEnvironment,
    // AiFillFacebook,
    // AiFillTwitterSquare,
    // AiFillInstagram,
    AiFillPhone,
} from "react-icons/ai";

function Contact() {
    const formRef = useRef(null);

    useEffect(() => {
        const form = formRef.current;
        const handleFormSubmit = (e) => {
            e.preventDefault();
            const formData = new FormData(form);
            const subject = formData.get('subject');
            const message = formData.get('message');
            const firstName = formData.get('firstName');
            const lastName = formData.get('lastName');
            const phone = formData.get('phone');
            const mailtoUrl = `mailto:unityekeoba18@gmail.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(`First Name: ${firstName}\nLast Name: ${lastName}\nPhone: ${phone}\n\nMessage: ${message}`)}`;
            window.location.href = mailtoUrl;
        };

        form.addEventListener('submit', handleFormSubmit);
        return () => {
            form.removeEventListener('submit', handleFormSubmit);
        };
    }, []);

    return (
        <div className="max-w-6xl mx-auto text-black">
            <h1 className="text-center text-[30px] font-semibold">Contact Me</h1>
            <p className="text-center">Any question or remarks? Just write me a message!</p>
            <div className="mt-5 flex flex-wrap justify-center items-center">
                <div className="max-w-[500px] h-[400px] border-4 border-[#323232]">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3965.0814933943984!2d5.609108!3d6.383483!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x10472cd14cef3f11%3A0xa539e625f9bb0894!2s4%20Eyeye%20St%2C%20Uselu%2C%20Benin%20City%20300103%2C%20Edo!5e0!3m2!1sen!2sng!4v1718648467212!5m2!1sen!2sng" style={{ border: "0", width: "100%", height: "100%" }} loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className="ml-[50px] md-down:ml-0 md-down:flex md-down:justify-center mt-[30px]">
                    <form id="contact-form" ref={formRef}>
                        <div className="flex flex-wrap justify-between md-down:justify-center">
                            <div className="w-[300px]">
                                <label htmlFor="firstName">First Name:</label>
                                <input className="md-down:w-full bg-transparent block border-t-0 border-l-0 border-r-0 border-b-2 border-b-black" type="text" id="firstName" name="firstName" required />
                            </div>

                            <div className="w-[300px] md-down:mt-5"><label htmlFor="lastName">Last Name:</label>
                                <input className="md-down:w-full bg-transparent block border-t-0 border-l-0 border-r-0 border-b-2 border-b-black" type="text" id="lastName" name="lastName" required /></div>
                        </div>

                        <div className="flex flex-wrap mt-5 md-down:justify-center">
                            <div className="w-[300px]">
                                <label htmlFor="email">Email:</label>
                                <input className="md-down:w-full bg-transparent block border-t-0 border-l-0 border-r-0 border-b-2 border-b-black" type="email" id="email" name="email" required />
                            </div>

                            <div className="w-[300px] md-down:mt-5">
                                <label htmlFor="phone">Phone Number:</label>
                                <input className="md-down:w-full bg-transparent block border-t-0 border-l-0 border-r-0 border-b-2 border-b-black" type="tel" id="phone" name="phone" required />
                            </div>
                        </div>

                        <div className="mt-5 md-down:mx-auto md-down:max-w-[300px]">
                            <label htmlFor="subject">Select Subject:</label>
                            <ul className="flex items-center gap-5 w-full flex-wrap md-down:justify-center">
                                <li className="flex items-center gap-3">
                                    <input type="radio" id="subject1" name="subject" value="General Inquiry" />
                                    <label htmlFor="subject1">General Inquiry</label>
                                </li>
                                <li className="flex items-center gap-3">
                                    <input type="radio" id="subject2" name="subject" value="Support Request" />
                                    <label htmlFor="subject2">Support Request</label>
                                </li>
                                <li className="flex items-center gap-3">
                                    <input type="radio" id="subject3" name="subject" value="Other" />
                                    <label htmlFor="subject3">Other</label>
                                </li>
                            </ul>
                        </div>

                        <div className="mt-5 md-down:mx-auto md-down:max-w-[300px]">
                            <label htmlFor="message">Message:</label>
                            <textarea className="md-down:w-full w-[500px] bg-transparent block border-2 border-black" id="message" name="message" required placeholder="Write your message" ></textarea>
                        </div>

                        <div className="md-down:max-w-[300px] mx-auto">
                        <button className="bg-[#323232] py-3 px-5 text-white rounded-lg mt-5 md-down:ml-2" type="submit">Send Message</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Contact;
