import React, { useRef, useEffect, useState } from 'react';
import { MobileMenuIconLight } from './icons';
import { useLocation, useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { MdClose } from "react-icons/md";

const MobileMenu = () => {
  const modalRef = useRef();
  const [pageState, setPageState] = useState("Sign In");
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Add state for menu open/close

  const location = useLocation();
  const navigate = useNavigate();

  const pathMachRoute = (route) => {
    if (route === location.pathname) {
      return true;
    }
  }

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        closeHamburger();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleHamburger = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const openHamburger = () => {
    setIsMenuOpen(true);
  };

  const closeHamburger = () => {
    setIsMenuOpen(false);
  };

  const auth = getAuth();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setPageState("Profile");
      } else {
        setPageState("Sign In");
      }
    });
  }, [auth]);

  return (
    <div className='flex md:hidden cursor-pointer text-[30px] text-white'>
        <div onClick={toggleHamburger}>
          {isMenuOpen ? <MdClose /> : <MobileMenuIconLight />}
        </div>
      <div 
        ref={modalRef} 
        className={`fixed inset-0 h-[100%] w-[50%] mt-[45px] flex items-center justify-center bg-[#323232] z-custom-index ${isMenuOpen ? 'block' : 'hidden'}`}
        id="hamburger"
        style={{ right: 0, left: 'auto' }}
      >
        <div className="relative justify-center flex flex-col w-[100%] mx-[auto] h-[auto] items-center">
          <div className='gap-8 items-center flex flex-col'>
            <li className={`text-white text-base font-ClashRegular hover:text-link-hover hover:font-ClashSemiBold hover:text-link-hover ${pathMachRoute("/") && "text-black !border-b-[#E0D9D3]"}`} onClick={() => { navigate("/"); closeHamburger(); }}>Home</li>
            <li className={`text-white text-base font-ClashRegular hover:text-link-hover hover:font-ClashSemiBold hover:text-link-hover ${pathMachRoute("/about") && "text-black !border-b-[#E0D9D3]"}`} onClick={() => { navigate("/about"); closeHamburger(); }}>About Me</li>
            <li className={`text-white text-base font-ClashRegular hover:text-link-hover hover:font-ClashSemiBold hover:text-link-hover ${pathMachRoute("/blogs") && "text-black !border-b-[#E0D9D3]"}`} onClick={() => { navigate("/blogs"); closeHamburger(); }}>Blogs</li>
          </div>
          <div className='items-center mt-2'>
            <button className={`bg-[#E0D9D3] cursor-pointer py-2 px-5 text-sm font-semibold text-black rounded-lg border-b-[3px] border-b-transparent ${(pathMachRoute("/contact") || pathMachRoute("/contact")) && "text-black !border-b-red-500"}`} onClick={() => { navigate("/contact"); closeHamburger(); }}>
              Contact Me
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
