import first from "../assets/svg/Dev Royale.png"
import second from "../assets/svg/second.svg"
import third from "../assets/svg/third.svg"
import fourth from "../assets/svg/fourth.svg"
// import fifth from "../assets/svg/fifth.svg"
const About = () => {
    return (
        <main className="max-w-[1000px] px-2 mx-auto text-black">
            <h1 className="text-3xl text-center mt-6 font-bold">About Me</h1>
            <div className="flex justify-between flex-wrap align-center items-center mt-[20px]">
                <div className="max-w-[500px] mx-auto">
                    <h2>I am Unity Ekeoba, a creative web developer with over three years of experience. My skills extend beyond web development to include creative design, mobile app development, and smart contract engineering.
                        Founder of Swift Liaison an estate management platform <a className="font-bold underline" href="http://swiftliaison.vercel.app">Swift Liaison</a>
                    </h2>
                   
                    <h2 className="mt-5 font-semibold">Table of Content</h2>
                    <ul>
                        <a className="block bg-[#323232] mt-3 py-2 pl-3 text-white" href="#service">Services</a>
                        <a className="block bg-[#323232] mt-3 py-2 pl-3 text-white" href="#skills">Soft Skills</a>
                        {/* <a className="block bg-gray-200 mt-3 py-2 pl-3" href="#safety">Safety</a> */}
                        {/* <a className="block bg-gray-200 mt-3 py-2 pl-3" href="">Sell Like a PRO!</a> */}
                    </ul>
                </div>
                <div className="w-[400px] max-w-[400px] mx-auto mt-5">
                    <img src={first} alt="about-pic" className="w-[100%]" />
                </div>
            </div>

        </main>
    )
}

export default About;