import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { db } from "../firebase";
import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
} from "firebase/storage";
import BlogItem from './BlogItem';

const CreateBlog = () => {
    const [blogs, setBlogs] = useState([]);
    const [title, setTitle] = useState('');
    const [tags, setTags] = useState('');
    const [image, setImage] = useState(null);
    const [content, setContent] = useState('');
    const [editingBlogId, setEditingBlogId] = useState(null);

    const storage = getStorage();

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const blogsCollection = collection(db, 'blogs');
                const blogsSnapshot = await getDocs(blogsCollection);
                const blogsData = blogsSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setBlogs(blogsData);
            } catch (error) {
                console.error('Error fetching blogs:', error);
                toast.error('Error fetching blogs!');
            }
        };

        fetchBlogs();
    }, []);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
    };

    const handleUpload = async () => {
        if (!image) return;
        const storageRef = ref(storage, image.name);
        const uploadTask = uploadBytesResumable(storageRef, image);

        uploadTask.on('state_changed',
            (snapshot) => {
                // Handle progress
            },
            (error) => {
                console.error('Error uploading image:', error);
                toast.error('Error uploading image!');
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                    const newBlog = {
                        title,
                        imageUrl: downloadURL,
                        content,
                        tags,
                        createdAt: new Date(),
                    };

                    if (editingBlogId) {
                        await updateDoc(doc(db, 'blogs', editingBlogId), newBlog);
                        toast.success('Blog updated successfully!');
                    } else {
                        await addDoc(collection(db, 'blogs'), newBlog);
                        toast.success('Blog added successfully!');
                    }

                    setTitle('');
                    setTags('');
                    setImage(null);
                    setContent('');
                    setEditingBlogId(null);
                });
            }
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (image) {
            await handleUpload();
        } else {
            const newBlog = {
                title,
                content,
                tags,
                createdAt: new Date(),
            };

            if (editingBlogId) {
                await updateDoc(doc(db, 'blogs', editingBlogId), newBlog);
                toast.success('Blog updated successfully!');
            } else {
                await addDoc(collection(db, 'blogs'), newBlog);
                toast.success('Blog added successfully!');
            }

            setTitle('');
            setTags('');
            setContent('');
            setEditingBlogId(null);
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteDoc(doc(db, 'blogs', id));
            setBlogs((prevBlogs) => prevBlogs.filter((blog) => blog.id !== id));
            toast.success('Blog deleted successfully!');
        } catch (error) {
            console.error('Error deleting blog:', error);
            toast.error('Error deleting blog!');
        }
    };

    const handleEdit = (blog) => {
        setTitle(blog.title);
        setTags(blog.tags);
        setContent(blog.content);
        setEditingBlogId(blog.id);
    };

    return (
        <div className="blog-admin text-white max-w-6xl mx-auto">
            <form onSubmit={handleSubmit} className="flex flex-col gap-3 items-center max-w-[1200px] mx-auto">
                <div className="input-group w-[350px] mb-4">
                    <label>Title:</label>
                    <input
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                        className="w-full bg-white text-black"
                    />
                </div>
                
                <div className="input-group w-[350px] mb-4">
                    <label>Content:</label>
                    <textarea
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        required
                        className="w-full bg-white text-black"
                    ></textarea>
                </div>
                <div className="input-group w-[350px] mb-4">
                    <label>Tags:</label>
                    <textarea
                        value={tags}
                        onChange={(e) => setTags(e.target.value)}
                        required
                        className="w-full bg-white text-black"
                    ></textarea>
                </div>
                <div className="input-group w-[350px] mb-4">
                    <label>Upload Image:</label>
                    <input type="file" onChange={handleImageChange} className="w-full border rounded py-1 px-3" />
                </div>
                <button type="submit" className="bg-[#323232] py-2 px-5 rounded mt-3 text-white">{editingBlogId ? 'Update Blog' : 'Add Blog'}</button>
            </form>

            <ul className="sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 mt-6">
                {blogs.map((blog) => (
                    <BlogItem
                        key={blog.id}
                        blog={blog}
                        id={blog.id}
                        onEdit={() => handleEdit(blog)}
                        onDelete={() => handleDelete(blog.id)}
                    />
                ))}
            </ul>
        </div>
    );
};

export default CreateBlog;
