import React from "react";
function Privacy() {
    return (
        <div className="max-w-6xl mx-auto">
            <h2 className="mt-5 text-[50px]">Privacy Policy</h2>
            <p className="mt-3 text-[13px]">This Privacy Policy explains what personal data is collected when you use Zee Service Finder and the services provided
                through (together the “Service”), how such personal data will be used and shared.</p>
            <p className="mt-3 text-[14px]">BY USING THE SERVICE, YOU PROMISE US THAT (I) YOU HAVE READ, UNDERSTAND AND AGREE TO THIS PRIVACY POLICY, AND (II) YOU ARE OVER 16 YEARS OF AGE (OR HAVE HAD YOUR PARENT OR GUARDIAN READ AND AGREE TO THIS PRIVACY POLICY FOR YOU).
                If you do not agree, or are unable to make this promise, you must not use the Service.
                In such case, you must contact the support team via email to request deletion of your account and data.</p>
            <p className="mt-3">“Process”, in respect of personal data, includes to collect, store, and disclose to others.</p>

            <ol className="mt-5">
                <h2 className="text-[#323232] text-[30px]">TABLE OF CONTENTS</h2>
                <li className="ml-5 mt-2">1. PERSONAL DATA CONTROLLER</li>
                <li className="ml-5 mt-2">2. CATEGORIES OF PERSONAL DATA WE COLLECT</li>
                <li className="ml-5 mt-2">3. DATA PROTECTION PRINCIPLES</li>
                <li className="ml-5 mt-2">4. FOR WHAT PURPOSES WE PROCESS PERSONAL DATA</li>
                <li className="ml-5 mt-2">5. UNDER WHAT LEGAL BASES WE PROCESS YOUR PERSONAL DATA</li>
                <li className="ml-5 mt-2">6. WITH WHOM WE SHARE YOUR PERSONAL DATA</li>
                <li className="ml-5 mt-2">7. AVAILABLE REMEDIES</li>
                <li className="ml-5 mt-2">8. NO LIMITATION CLAUSE</li>
                <li className="ml-5 mt-2">9. HOW YOU CAN EXERCISE YOUR PRIVACY RIGHTS</li>
                <li className="ml-5 mt-2">10. AGE LIMITATION</li>
                <li className="ml-5 mt-2">11. INTERNATIONAL DATA TRANSFERS</li>
                <li className="ml-5 mt-2">12. CHANGES TO THIS PRIVACY POLICY</li>
                <li className="ml-5 mt-2">13. DATA RETENTION</li>
                <li className="ml-5 mt-2">14. CONTACT US</li>
            </ol>

            <h2 className="mt-5 text-[25px] font-semibold">1. PERSONAL DATA CONTROLLER</h2>
            <p className="mt-3 text-[13px]">zeeservicefiner.com Online Marketplace Nigeria Limited, a company registered in the Federal Republic of Nigeria (with registered office at 350, Borno Way, Yaba, Lagos, Nigeria) will be the controller of your personal data.

            </p>
            <p className="mt-3 text-[13px]">Cars45 Limited, a company registered in the Federal Republic of Nigeria (with registered office at 4th Floor, CIPM House, CIPM Avenue, CBD, Alausa, Ikeja, Lagos) will be the joint controller of the categories of personal data as follows:</p>
            <p className="mt-3 text-[13px]">In relation to certain categories of personal data expressly specified below 2.3.5</p>

            <ul className="mt-3 text-[13px]">
                <li>a) car dealers' transaction data relating to sales at the car auction (paragraph 2.3.5.);</li>
                <li>b) personal ID, Bank ID and САС of car dealers (clause 2.1.);</li>
                <li>c) the Required Information of car sellers and car dealers (clause 2.1.).</li>
            </ul>

            <h2 className="mt-5 text-[25px] font-semibold">2. CATEGORIES OF PERSONAL DATA WE COLLECT</h2>
            <p className="text-[13px]">We collect data you give us voluntarily (for example, an email address). We also collect data automatically (for example, your IP address).</p>
            <p className="mt-3 font-semibold text-[14px]">2.1 Data you give us.</p>
            <p className="mt-3 text-[13px]">You may be asked to provide us information about yourself when you register for and/or use the Service. This information includes: first name, last name, phone number, email, gender, date of birth (together “Required Information”), address details, working hours etc.,
                as well as access to your photos, albums and their metadata. You will need to provide us with
                your ID or similar identification document if you want to post advertisement in Mobile Phones category, unblock your account
                or make duplicate account primary. You will need to provide us with your Bank ID and САС
                (certificate of incorporation of legal entity) if you want to participate in car auction as a dealer.</p>
            <p className="mt-3 text-[13px]">To use our Service and register an account, you will need to provide Required Information. You will be able to use the Service even if you do not give this data to us, but some Service’s functionality may be limited to you (for example, if you do not
                register an account, you will not be able to chat with other users, post ads, see contact details of other users).</p>
            <p className="mt-3 text-[13px]">
                Sometimes you may also need to provide us additional information in the communication with our Support Team in order to fulfill your request
                (for example, if your account was previously blocked, we may ask you to confirm your identity by providing an ID document).
            </p>
            <p className="mt-3 text-[13px]">
                While posting an announcement on the Service, you can decide to provide additional personal information on yourself. For example, you can decide to make available your CV or any photos of you or bearing your personal information. You acknowledge that by providing your personal data in the announcement you are making such data publicly available. In addition, you acknowledge and agree that we will make public some personal data from your profile to provide the Service, - it will enable us to facilitate communication and transactions between the users. In particular, you acknowledge and agree that
                your email will be shown as a part of your advertisement in the Seeking Work CVs section to some users with the posted advertisement in the Jobs section.
            </p>
            <p className="mt-3 text-[13px]">You should carefully consider risks associated with the fact that you make certain information –
                in particular, you phone number, address, photos, passport and financial details or exact location – publicly available.</p>
            <p className="mt-3 text-[13px]">If you want to apply for a car loan, we shall provide us with your employment Type (Salary Earner or Business Owner), Monthly Income/Salary and Bank Verification Number(BVN).
                We will use this information for the calculation of amounts for the first and subsequent monthly installments.</p>
            <p className="mt-3 font-semibold text-[14px]">2.2. Data provided to us by third parties</p>
            <p className="mt-3 text-[13px]">
                When you decide to log in using Meta, we get personal data from your Meta account. This includes your profile image, name, and Meta ID. Unless you opt-out on the Meta Login screen, we will also collect other data,
                such as email address, gender, date of birth, friends list, and location as listed in your Meta profile.
            </p>
            <p className="mt-3 text-[13px]">
                For more information, please refer to the Meta Permissions Reference (describes the categories of information, which Meta may share with third parties and the set of requirements) and to the Meta Data policy. In addition,
                Meta lets you control the choices you made when connecting your Meta profile to the App on their Apps and Websites page.
            </p>
            <p className="mt-3 text-[13px]">
                When you log in with Google, we get your name, email address, profile picture and Google ID. We use your personal data from Google in accordance with this Privacy Policy. To know more about how
                Google processes your data, visit its Privacy Policy. To remove access granted to us, visit Google Permissions.
            </p>
            <p className="mt-3 text-[13px]">
                We may collect your name, official government ID number and photo image to confirm your identity. For this we will share this information with a 3rd party service provider, Smile Identity Inc., in order to validate it with the governmental sources. Smile Identity Inc. may use your ID number to collect the following additional data on you from the governmental sources: full name, date of birth, image, address, phone number, gender, government ID expiry date or other additional information associated with your ID number
                that the issuing authority may provide. Smile ID may process or store your data outside of the borders of your country.
            </p>

            <h2 className="mt-5 text-[25px] font-semibold">3. DATA PROTECTION PRINCIPLES</h2>
            <p className="mt-3 text-[13px]">
                In our data protection practices we strive, in particular, to provide that personal data is:
            </p>
            <ul>
                <li className="text-[13px]">processed in accordance with specific, legitimate and lawful purpose consented to by you;</li>
                <li className="text-[13px]">is adequate, accurate and without prejudice to the dignity of human person;</li>
                <li className="text-[13px]">stored only for the period within which it is reasonably needed; and</li>
                <li className="text-[13px]">secured against reasonably foreseeable hazards and breaches such as theft, cyberattack, viral attack, dissemination, manipulations of any kind, damage by rain, fire or exposure to other natural elements.</li>
            </ul>

            <h2 className="mt-5 text-[25px] font-semibold">4. FOR WHAT PURPOSES WE PROCESS YOUR PERSONAL DATA</h2>
            <p className="mt-3 text-[13px]">We process your personal data:</p>
            <p className="mt-3 font-semibold text-[14px]">4.1 To provide our Service</p>
            <p className="mt-3 text-[13px]">This includes enabling you to use the Service in a seamless manner
                and preventing or addressing Service errors or technical issues.</p>
            <p className="mt-3 font-semibold text-[14px]">4.2. To customize your experience</p>
            <p className="mt-3 text-[13px]">We process your personal data to adjust the content of the Service and make
                offers tailored to your personal preferences and interests.</p>
            <p className="mt-3 font-semibold text-[14px]">4.3. To manage your account and provide you with customer support</p>
            <p className="mt-3 text-[13px]">We process your personal data to respond to your requests for technical support, Service information or to any other communication you initiate. This includes accessing your account to address technical support requests. For this purpose, we may send you, for example, notifications or emails about
                the performance of our Service, security, payment transactions, notices regarding our Terms of Use or this Privacy Policy.</p>
            <p className="mt-3 font-semibold text-[14px]">4.4. To communicate with you regarding your use of our Service</p>
            <p className="mt-3 text-[13px]">We communicate with you, for example, by push notifications or in the chat. As a result, you may, for example, receive a notification whether on the Website or via email that you received a new message on Jiji. To opt out of receiving push notifications, you need to change the settings on your browser or mobile device. To opt out of certain type of emails, you need to follow unsubscribe
                link located in the footer of the email, by contacting our support team at support@jiji.ng, or in your profile settings.</p>
            <p className="mt-3 text-[13px]">The services that we use for these purposes may collect data concerning the date and time when the message was viewed by our users,
                as well as when they interacted with it, such as by clicking on links included in the message.</p>
            <p className="mt-3 font-semibold text-[14px]">4.5. To research and analyze your use of the Service</p>
            <p className="mt-3 text-[13px]">This helps us to better understand our business, analyze our operations, maintain, improve, innovate, plan, design, and develop Jiji and our new products. We also use such data for statistical analysis purposes, to test and improve our offers. This enables us to better understand what features and sections of Jiji our users like more, what categories of users use our Service. As a consequence, we often decide how to improve Jiji based on the results obtained from this processing.
                For example, if we discover that Jobs section is not as popular as others, we may focus on improving it.</p>
            <p className="mt-3 font-semibold text-[14px]">4.6. To send you marketing communications</p>
            <p className="mt-3 text-[13px]">We process your personal data for our marketing campaigns. We may add your email address to our marketing list. As a result, you will receive information about our products, such as for example, special offers, and products of our partners. If you do not want to receive marketing emails from us, you can unsubscribe following instructions in the footer of the marketing
                emails, by contacting our support team at support@jiji.ng, or in your profile settings.</p>
            <p className="mt-3 text-[13px]">We may also show you advertisements on the Website, and send you push notifications for marketing purposes.</p>

            <h2 className="mt-5 text-[25px] font-semibold">5. UNDER WHAT LEGAL BASES WE PROCESS YOUR PERSONAL DATA</h2>

            <p className="mt-3 text-[13px]">communicate with you regarding your use of our Service</p>
            <p className="mt-3 text-[13px]">This includes, for example, sending you push notifications reminding you that you have unread messages. The legitimate interest we rely on for this purpose is our
                interest to encourage you to use our Service more often. We also take into account the potential benefits to you.</p>
            <p className="mt-3 text-[13px]">research and analyze your use of the Service</p>
            <p className="mt-3 text-[13px]">Our legitimate interest for this purpose is our interest in improving our Service so that we understand users’
                preferences and are able to provide you with a better experience (for example, to make the use of our mobile application easier and more enjoyable, or to introduce and test new features).</p>

            <h2 className="mt-5 text-[25px] font-semibold">6. WITH WHOM WE SHARE YOUR PERSONAL DATA</h2>
            <p className="mt-3 text-[13px]">We share information with third parties that help us operate, provide, improve, integrate, customize, support, and market our Service. We may share some sets of personal data, in particular,
                for purposes and with parties indicated in Section 2 of this Privacy Policy. The types of third parties we share information with include, in particular:</p>
            <p className="mt-3 font-semibold text-[14px]">6.1. Service providers</p>
            <p className="mt-3 text-[13px]">We share personal data with third parties that we hire to provide services or perform business functions on our behalf, based on our instructions. We may share your personal information with the following types of service providers:</p>
            <p className="mt-3 text-[13px]">cloud storage providers (Amazon, DigitalOcean, Hetzner)</p>
            <p className="mt-3 text-[13px]">data analytics providers (Facebook, Google, Appsflyer)</p>
            <p className="mt-3 text-[13px]">login service providers (Google, Apple, Facebook, Truecaller)</p>
            <p className="mt-3 text-[13px]">marketing partners (in particular, social media networks, marketing agencies, email delivery services; such as Facebook, Google)</p>
            <p className="mt-3 text-[13px]">local banks and other financial institutions (Access Bank, Sterling Bank, Union Bank, Sovereign Finance, Purple Money, QuickCheck, etc.);</p>

            <p className="mt-3 font-semibold text-[14px]">6.2. Law enforcement agencies and other public authorities</p>
            <p className="mt-3 text-[13px]">We may use and disclose personal data to enforce our Terms of Use, to protect our rights, privacy, safety, or property, and/or that of our affiliates, you or others, and to respond to requests from courts, law enforcement
                agencies, regulatory agencies, and other public and government authorities, or in other cases provided for by law.</p>
            <p className="mt-3 font-semibold text-[14px]">6.3. Third parties as part of a merger or acquisition</p>
            <p className="mt-3 text-[13px]">As we develop our business, we may buy or sell assets or business offerings. Customers’ information is generally one of the transferred business assets in these types of transactions. We may also share such information with any affiliated entity (e.g. parent company or subsidiary) and may transfer such information in the course of a corporate transaction, such as
                the sale of our business, a divestiture, merger, consolidation, or asset sale, or in the unlikely event of bankruptcy.</p>

            <h2 className="mt-5 text-[25px] font-semibold">7. AVAILABLE REMEDIES</h2>
            <p className="mt-3 text-[13px]">If there is a data breach that may cause a risk for the rights and freedoms of individuals, we will notify competent supervisory authority, when such notification is mandatory. If the risk is assessed by us as “high”, we will also notify the affected data subjects without undue delay. The time frame for such notification will be developed by us
                on the basis of number of affected data subjects and time needed to collect contact information for notifications.</p>
            <p className="mt-3 text-[13px]">If the measures taken or proposed to be taken in response to the breach did not address your concerns, you have the right to lodge a
                complaint with a competent supervisory authority, or to seek redress in a court of competent jurisdiction.</p>
            <h2 className="mt-5 text-[25px] font-semibold">8. NO LIMITATION CLAUSE</h2>
            <p className="mt-3 text-[13px]">No limitation of liability shall avail us in case we act in breach of the principles set out in Section 3.</p>
            <h2 className="mt-5 text-[25px] font-semibold">9. HOW YOU CAN EXERCISE YOUR RIGHTS</h2>
            <p className="mt-3 text-[13px]">To be in control of your personal data, you have the following rights:</p>
            <p className="mt-3 text-[13px]">Accessing / reviewing / updating / correcting your personal data. You may review, edit, or change the personal data that you had previously provided to Jiji in the settings section on the Website.

                You may also request a copy of your personal data collected during your use of the Service.</p>
            <p className="mt-3 text-[13px]">Deleting your personal data. You can request erasure of your personal data by sending us an email
                at support@jiji.ng or you can delete your account via the link.</p>
            <p className="mt-3 text-[13px]">When you request deletion of your personal data, we will use reasonable efforts to honor your request. In some cases, we may be legally required to keep some of the data for a certain time;
                in such event, we will fulfill your request after we have complied with our obligations.</p>
            <p className="mt-3 text-[13px]">Objecting to or restricting the use of your personal data (including for direct marketing purposes).
                You can ask us to stop using all or some of your personal data or limit our use thereof.</p>
            <p className="mt-3 text-[13px]">The right to lodge a complaint with supervisory authority. We would love you to contact us directly, so we could address your concerns. Nevertheless,
                you have the right to lodge a complaint with a competent data protection supervisory authority. </p>
            <p className="mt-3 text-[13px]">To exercise any of your privacy rights, please send a request to support@jiji.ng.</p>

            <h2 className="mt-5 text-[25px] font-semibold">10. AGE LIMITATION</h2>
            <p className="mt-3 text-[13px]">We do not knowingly process personal data from persons under 16 years of age.
                If you learn that anyone younger than 16 has provided us with personal data, please contact us at support@jiji.ng.</p>
            <h2 className="mt-5 text-[25px] font-semibold">11. INTERNATIONAL DATA TRANSFERS
            </h2>
            <p className="mt-3 text-[13px]">We may transfer personal data to countries other than the country in which the data was originally collected in order to provide the Service set forth in the Terms of Use and for purposes indicated in this Privacy Policy. If these countries do not have the same data protection laws as the country in which you initially provided the information, we deploy special safeguards. In particular, if we transfer personal data originating from the EEA to countries with not adequate level of data protection, we use one of the following legal bases: (i) Standard Contractual Clauses approved by the European Commission (details available here), 
            or (ii) the European Commission adequacy decisions about certain countries (details available here).</p>
            <h2 className="mt-5 text-[25px] font-semibold">12. CHANGES TO THIS PRIVACY POLICY
            </h2>
            <p className="mt-3 text-[13px]">We may modify this Privacy Policy from time to time. If we decide to make material changes to this Privacy Policy, you will be notified through our Service or by other available means and will have an opportunity to review the revised Privacy Policy. By continuing to access or 
            use the Service after those changes become effective, you agree to be bound by the revised Privacy Policy.</p>
            <h2 className="mt-5 text-[25px] font-semibold">13. DATA RETENTION
            </h2>
            <p className="mt-3 text-[13px]">We will store your personal data for as long as it is reasonably necessary for achieving the purposes set forth in this Privacy Policy (including providing the Service to you), which includes (but is not limited to) the period during which you have a Jiji account. We will also retain and use your personal data as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements. We will store your hashed email address, phone number, identification documents and 
            information on the fact your account has been blocked after fulfilling your request on deleting your personal data.</p>
            <p className="mt-3 text-[13px]">We will also retain and use your personal data as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>
            <h2 className="mt-5 text-[25px] font-semibold">14. CONTACT US
            </h2>
            <p className="mt-3 text-[13px]">You may contact us at any time for details regarding this Privacy Policy and its previous versions. For any questions concerning your account or your personal data please contact us at support@jiji.ng.</p>
            <p className="mt-3 text-[13px]">Effective as of: 3 Jun 2024</p>
        </div>
    )
}
export default Privacy;