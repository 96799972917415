import { Link } from "react-router-dom";
import Moment from "react-moment";
import { FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";

const BlogItem = ({ blog, id, onEdit, onDelete }) => {
  return (
    <li className="relative text-black flex flex-col justify-between items-center shadow-md shadow-[#00000] hover:shadow-xl  overflow-hidden transition-shadow duration-150 m-[10px]"
      style={{
        background: "#fff"
      }}>
      <Link className="contents" to={`/blog/${id}`}>
        {blog.imageUrl && (
          <img
            className="h-auto sm:h-[500px] w-full object-cover hover:scale-90 transition-scale duration-200 ease-in"
            loading="lazy"
            src={blog.imageUrl}
            alt={blog.title}
          />
        )}
        <div className="w-full p-[10px]">
          <Moment
            className="text-xs font-semibold"
            fromNow
          >
            {blog.createdAt?.toDate()}
          </Moment>
          <p className="font-semibold text-xl mb-2 truncate">{blog.title.slice(0, 20)}...</p>
          <p className="text-gray-400 truncate">{blog.content.slice(0, 20)}...</p>
        </div>
      </Link>
      {onDelete && (
        <FaTrash
          className="absolute bottom-2 right-2 h-[14px] cursor-pointer text-red-500"
          onClick={() => onDelete(blog.id)}
        />
      )}
      {onEdit && (
        <MdEdit
          className="absolute bottom-2 right-7 h-4 cursor-pointer"
          onClick={() => onEdit(blog.id)}
        />
      )}
    </li>
  );
};

export default BlogItem;
