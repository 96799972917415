import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, query, onSnapshot } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { toast } from 'react-toastify';

function Notification({ onClose }) {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const auth = getAuth();

  useEffect(() => {
    const q = query(collection(db, 'notifications'));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let notificationsData = [];
      querySnapshot.forEach((doc) => {
        notificationsData.push({ id: doc.id, data: doc.data() });
      });
      setNotifications(notificationsData);
      setLoading(false);
    }, (err) => {
      setError(err);
      setLoading(false);
    });

    return () => {
      // Unsubscribe from the snapshot listener when component unmounts
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    // Check for unread notifications and show toast
    const unreadNotifications = notifications.filter(notification => !notification.data.read);
    if (unreadNotifications.length > 0) {
      toast.info('New notification unread');
    }
  }, [notifications]);

  const handleMarkAsRead = async (id) => {
    try {
      await db.collection('notifications').doc(id).update({
        read: true,
      });
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const handleLearnMore = async (notificationId) => {
    onClose(); // Close the notification modal
    await handleMarkAsRead(notificationId); // Mark the notification as read
    window.open(`/notification/${notificationId}`, '_blank'); // Open NotificationDetails in a new tab
  };

  if (loading) {
    return <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">Loading...</div>;
  }

  if (error) {
    return <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">Error: {error.message}</div>;
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg max-w-[300px] p-6">
        <h2 className="text-xl font-semibold mb-4">Notifications</h2>
        {notifications.length ? (
          <ul>
            {notifications.map((notification) => (
              <li key={notification.id} className="flex justify-between items-center">
                <span>
                  {notification.data && notification.data.title 
                    ? notification.data.title.split(' ').slice(0, 3).join(' ') + '...' 
                    : 'Title not available'}
                </span>
                <button 
                  onClick={() => handleLearnMore(notification.id)} 
                  className={`text-blue-500 underline ${notification.data.read ? 'text-red-500' : ''}`}
                >
                  Learn More
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <p>No notifications to display.</p>
        )}
        <button onClick={onClose} className="mt-4 bg-[#323232] hover:border hover:bg-transparent hover:text-black text-white py-2 px-4 rounded">
          Close
        </button>
      </div>
    </div>
  );
}

export default Notification;
