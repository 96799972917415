import { useEffect, useState } from "react";
import {
  collection,
  getDocs,
} from "firebase/firestore";
import { db } from "../firebase";
import Hero from "../components/Hero";
import { toast } from "react-toastify";
import BlogItem from "../components/BlogItem"

export default function Home() {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const blogsCollection = collection(db, 'blogs');
        const blogsSnapshot = await getDocs(blogsCollection);
        const blogsData = blogsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBlogs(blogsData);
      } catch (error) {
        console.error('Error fetching blogs:', error);
        toast.error('Error fetching blogs!');
      }
    };

    fetchBlogs();
  }, []);


  return (
    <div>
      <Hero />
      <div className="max-w-4xl mx-auto pt-4 space-y-6">


        <div className="m-2 mb-6">
          <h2 className="px-3 text-2xl text-black mt-6 font-semibold text-center">
            All My Stories
          </h2>
          <ul className="sm:grid sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 mt-10">
            {blogs.map((blog) => (
              <BlogItem
                key={blog.id}
                blog={blog}
                id={blog.id}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
