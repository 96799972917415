import { useEffect, useState, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import Spinner from "../components/Spinner";

import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  EffectFade,
  Autoplay,
} from "swiper";
import "swiper/swiper-bundle.css";

import {
  FaShare,
  FaMapMarkedAlt,
  FaBed,
  FaBath,
  FaParking,
  FaChair,
} from "react-icons/fa";

import { getAuth } from "firebase/auth";
import Contact from "../components/Contact";

import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

export default function Listing2() {
  const auth = getAuth();
  const params = useParams();
  const [listing, setListing] = useState(null);
  const [loading, setLoading] = useState(true);
  const [shareLinkCopied, setShareLinkCopied] = useState(false);
  const [contactUser, setContactUser] = useState(false);
  const [user, setUser] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const modalRef = useRef(null); // Reference to the modal

  useEffect(() => {
    const fetchListing = async () => {
      const docRef = doc(db, "listings2", params.listingId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setListing(docSnap.data());
        const userDoc = await getDoc(doc(db, "users", docSnap.data().userRef));
        if (userDoc.exists()) {
          setUser(userDoc.data());
        }
        setLoading(false);
      }
    };
    fetchListing();
  }, [params.listingId]);

  // Close modal when clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setSelectedImage(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <main>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, EffectFade, Autoplay]}
        navigation
        pagination={{ type: "progressbar" }}
        slidesPerView={1}
        effect="fade"
        autoplay={{ delay: 3000 }}
      >
        {listing.imgUrls.map((url, index) => (
          <SwiperSlide key={index}>
            <div
              className="relative w-full overflow-hidden h-[300px] cursor-pointer"
              style={{
                background: `url(${listing.imgUrls[index]}) center no-repeat`,
                backgroundSize: "cover",
              }}
              onClick={() => setSelectedImage(listing.imgUrls[index])}
            ></div>
          </SwiperSlide>
        ))}
      </Swiper>

      {selectedImage && (
        <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50" onClick={() => setSelectedImage(null)}>
          <div className="bg-white p-4 rounded-lg" ref={modalRef}>
            <img src={selectedImage} alt="Full size" className="max-w-full max-h-full" />
            <button className="absolute top-0 right-0" onClick={() => setSelectedImage(null)}>Close</button>
          </div>
        </div>
      )}

      <div
        className="fixed top-[13%] right-[3%] z-10 bg-white cursor-pointer border-2 border-gray-400 rounded-full w-12 h-12 flex justify-center items-center"
        onClick={() => {
          navigator.clipboard.writeText(window.location.href);
          setShareLinkCopied(true);
          setTimeout(() => {
            setShareLinkCopied(false);
          }, 2000);
        }}
      >
        <FaShare className="text-lg text-slate-500" />
      </div>

      {shareLinkCopied && (
        <p className="fixed top-[23%] right-[5%] font-semibold border-2 border-gray-400 rounded-md bg-white z-10 p-2">
          Link Copied
        </p>
      )}

      <div className="m-4 flex flex-col md:flex-row max-w-6xl lg:mx-auto p-4 rounded-lg shadow-lg bg-white lg:space-x-5">
        <div className="w-full">
          <p className="text-2xl font-bold mb-3 text-blue-900">
            {listing.name} - ₦{" "}
            {listing.offer
              ? listing.discountedPrice
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : listing.regularPrice
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            {listing.type === "fashion" ? " / month" : ""}
          </p>
          <p className="flex flex-wrap items-center mt-6 mb-3 font-semibold ">
            <FaMapMarkedAlt className="text-green-700 mr-1" /> {listing.homeAddress}
          </p>
          <div className="flex justify-start items-center space-x-4 w-[75%]">
            <p className="bg-red-800 w-full max-w-[200px] rounded-md p-1 text-white text-center font-semibold shadow-md ">
              {listing.type === "fashion" ? "Fashion" : "Beauty"}
            </p>
            {listing.offer && (
              <p className="w-full max-w-[200px] bg-green-800 rounded-md p-1 text-white text-center font-semibold shadow-md">
                ₦{+listing.regularPrice - +listing.discountedPrice} discount
              </p>
            )}
          </div>
          <p className="mt-3 mb-3">
            <span className="font-semibold">Description - </span>
            {listing.description}
          </p>
          {/* <ul className="flex items-center space-x-2 sm:space-x-10 text-sm font-semibold mb-6">
            <li className="flex items-center whitespace-nowrap">
              <FaBed className="text-lg mr-1" />
              {+listing.quantity > 1 ? `${listing.quantity} Available` : "1 Available"}
            </li>
          </ul> */}
          {user && (
            <div className="flex items-center mb-4">
              <Link to={`/user-profile/${user.name}`} className="mr-4">
                <img
                  src={user.photoURL}
                  alt="Profile"
                  className="w-12 h-12 object-cover rounded-full cursor-pointer"
                />
              </Link>
              <p className="text-lg font-semibold">{user.name}</p>
            </div>
          )}
          {listing.userRef !== auth.currentUser?.uid && !contactUser && (
            <div className="mt-6">
              <button
                onClick={() => setContactUser(true)}
                className="px-7 py-3 bg-[#323232] text-white font-medium text-sm uppercase rounded  shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg w-full text-center transition duration-150 ease-in-out"
              >
                Contact User
              </button>
            </div>
          )}
        </div>

        {contactUser && (
          <Contact userRef={listing.userRef} listing={listing} />
        )}

        {/* <div className="w-full h-[200px] md:h-[400px] z-10 overflow-x-hidden mt-6 md:mt-0 md:ml-2">
          <MapContainer
            center={[listing.geolocation.lat, listing.geolocation.lng]}
            zoom={13}
            scrollWheelZoom={false}
            style={{ height: "100%", width: "100%" }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker
              position={[listing.geolocation.lat, listing.geolocation.lng]}
            >
              <Popup>
                {listing.address}
              </Popup>
            </Marker>
          </MapContainer>
        </div> */}
      </div>
    </main>
  );
}
