import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaSquareTwitter } from "react-icons/fa6";


const Footer = () => {
    // const [pageState, setPageState] = useState(<SignInIcon />);
    const location = useLocation();
    const navigate = useNavigate();

    const pathMatchRoute = (route) => {
        if (route === location.pathname) {
            return true;
        }
    };

    // const [showNotificationPopup, setShowNotificationPopup] = useState(false);

    // const sendNotification = async (message) => {
    //     try {
    //         const notificationData = {
    //             userId: auth.currentUser.uid,
    //             message: message,
    //             timestamp: new Date(),
    //         };

    //         await addDoc(collection(db, 'notifications'), notificationData);
    //         toast.success('Notification sent successfully');
    //     } catch (error) {
    //         console.error('Error sending notification:', error);
    //         toast.error('Could not send notification');
    //     }
    // };

    // const auth = getAuth();

    // useEffect(() => {
    //     onAuthStateChanged(auth, (user) => {
    //         if (user) {
    //             setPageState("Profile");
    //         } else {
    //             setPageState("Sign In");
    //         }
    //     });
    // }, [auth]);
    return (
        <div className="left-0 right-0 bg-[#323232] border-t py-3 w-full text-white mt-5" >
            {/* <small className="text-sm font-semibold">Copyright | 2024 All Right Reserved</small> */}
            <div className="max-w-6xl mx-auto pt-5 flex justify-between md-down:flex-col md-down:px-3">
                <div>
                    <div>
                        <div className="w-[150px]">
                            <div className="flex items-center gap-2 text-white text-2xl">
                                <FaFacebookSquare />
                                <FaInstagramSquare />
                                <FaSquareTwitter />
                            </div>
                        </div>
                        {/* <p className="text-blue-300 text-[16px] p-0 font-medium self-end cursor-pointer" onClick={() => navigate("/")}>Zee Service Provider</p> */}
                    </div>
                    <p className="max-w-[300px] mt-5 text-[12px]">Unity Ekeoba is a creative web developer with other skills. Such as creative designs, mobile app development and blockchain development as well.</p>
                </div>
                <ul className="items-center">
                    <h2 className="font-semibold text-[20px]">Quick Links</h2>
                    <li title="Home Page" className={`hover:title cursor-pointer py-1 text-[15px] font-light text-white border-b-[3px] border-b-transparent ${pathMatchRoute("/") && "text-black !border-b-[#E0D9D3]"}`} onClick={() => navigate("/")}>Home</li>
                    <li title="Products" className={`hover:title cursor-pointer py-1 text-[15px] font-light text-white border-b-[3px] border-b-transparent ${pathMatchRoute("/about") && "text-black !border-b-[#E0D9D3]"}`} onClick={() => navigate("/about")}>About Me</li>
                    <li title="Recent Offers" className={`hover:title cursor-pointer py-1 text-[15px] font-light text-white  ${pathMatchRoute("/blogs") && "!text-[#fff]"}`} onClick={() => navigate("/blogs")}>Blogs</li>
                </ul>
                <ul className="items-center">
                    <h2 className="font-semibold text-[20px]">Follow Me</h2>
                    <Link to="https://x.com/Uekeoba" target="_blank" className={`hover:title block cursor-pointer py-1 text-[15px] font-light text-white border-b-[3px] border-b-transparent "text-black !border-b-[#E0D9D3]"}`}>Twitter</Link>
                    <Link to="https://web.facebook.com/unityekeoba25" target="_blank" className={`hover:title block cursor-pointer py-1 text-[15px] font-light text-white border-b-[3px] border-b-transparent "text-black !border-b-[#E0D9D3]"}`}>Facebook</Link>
                    <Link to="https://linkedin.com/in/unity-ekeoba-2a68a9259" target="_blank" className={`hover:title block cursor-pointer py-1 text-[15px] font-light text-white border-b-[3px] border-b-transparent "text-black !border-b-[#E0D9D3]"}`}>Instagram</Link>
                </ul>
            </div>
        </div>
    )
}

export default Footer;