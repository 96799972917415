import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { doc, getDocs, getDoc, collection, updateDoc, arrayUnion, query, where, orderBy } from "firebase/firestore";
import { db } from "../firebase";
import { getAuth } from "firebase/auth";
import Popup from "../components/PopupAd"
import { AddFriendIcon } from "../components/icons";
import ListingItem from "../components/ListingItem";

export default function PublicProfile() {
  const [user, setUser] = useState(null);
  const [isFriend, setIsFriend] = useState(false);
  const [showFullImage, setShowFullImage] = useState(false);
  const params = useParams();
  const name = params.name;
  const auth = getAuth();
  const [ad, setAd] = useState(null);
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        const fetchAds = async () => {
            try {
                const adsCollection = collection(db, 'ads');
                const adsSnapshot = await getDocs(adsCollection);
                const adsData = adsSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setAd(adsData[0]); // Get the first ad for the popup
                setShowPopup(true); // Show the popup
            } catch (error) {
                console.error('Error fetching ads:', error);
            }
        };

        fetchAds();
    }, []);

    const handleClosePopup = () => {
        setShowPopup(false);
    };

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userDoc = await doc(db, "users", auth.currentUser.uid);
        const userSnapshot = await getDocs(collection(db, "users"));
        const usersData = userSnapshot.docs.map((doc) => doc.data());
        const userFound = usersData.find((user) => user.name === name);
        if (userFound) {
          setUser(userFound);
          
          const currentUserDoc = await doc(db, "users", auth.currentUser.uid);
          const currentUserData = (await getDoc(currentUserDoc)).data();
          
          setIsFriend(currentUserData.friends && currentUserData.friends.some(friend => friend.name === userFound.name));
        } else {
          console.error("No user found with Name:", name);
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };
  
    fetchUserProfile();
  }, [name, auth.currentUser.uid]);

  const [listings, setListings] = useState([]);
  const [loadingListings, setLoadingListings] = useState(true);

  useEffect(() => {
    const fetchUserListings = async () => {
      try {
        // Find the user based on displayName
        const userQuery = query(collection(db, "users"), where("name", "==", name));
        const userSnapshot = await getDocs(userQuery);
  
        if (!userSnapshot.empty) {
          const userDoc = userSnapshot.docs[0];
          
          const listingRef = collection(db, "listings");
          const q = query(listingRef, where("userRef", "==", userDoc.id), orderBy("timestamp", "desc"));
          const querySnap = await getDocs(q);
          
          let listingsData = [];
          querySnap.forEach((doc) => {
            listingsData.push({ id: doc.id, data: doc.data() });
          });
          
          setListings(listingsData);
          setLoadingListings(false);
        } else {
          console.error("User not found with name:", name);
          setLoadingListings(false);
        }
      } catch (error) {
        console.error("Error fetching user listings:", error);
        setLoadingListings(false);
      }
    };
  
    fetchUserListings();
  }, [name]);
  

  const handleImageClick = () => {
    setShowFullImage(true);
  };

  const handleCloseImage = () => {
    setShowFullImage(false);
  };

  const addFriend = async () => {
    try {
      const userDocRef = doc(db, "users", auth.currentUser.uid);
      await updateDoc(userDocRef, {
        friends: arrayUnion({
          name: user.name,
          photoURL: user.photoURL,
        }),
      });
      alert(`Added ${user.name} as friend!`);
    } catch (error) {
      console.error("Error adding friend:", error);
    }
  };

  return (
    <div className="max-w-6xl mx-auto mt-10 px-4">
      {user ? (
        <div className="border rounded p-4">
          <div className="max-w-md mx-auto flex text-center justify-center align-center">
            {user.photoURL && (
              <>
                <img
                  src={user.photoURL}
                  alt="Profile"
                  className="w-32 h-32 object-cover rounded-full mb-2 cursor-pointer border-4 border-[#323232]"
                  onClick={handleImageClick}
                />
                {showFullImage && (
                  <div
                    className="fixed top-0 left-0 w-screen z-50 h-screen mx-auto flex justify-center items-center bg-black bg-opacity-50"
                    onClick={handleCloseImage}
                  >
                    <img
                      src={user.photoURL}
                      alt="Full Profile"
                      className="max-w-full max-h-full"
                    />
                  </div>
                )}
              </>
            )}
          </div>
          <h1 className="text-3xl font-semibold mb-4 text-center mb-1 w-full px-4 py-0 text-gray-700 text-center  transition ease-in-out">{user.displayName}</h1>
          <p className="mb-1 w-full px-4 py-0 text-xl text-gray-700 text-center transition ease-in-out">{user.email}</p>
          <p className="mb-1 max-w-[400px] mx-auto px-4 py-0 text-xl text-gray-700 text-center transition ease-in-out">{user.bio}</p>
          {!isFriend && (
            <button onClick={addFriend} className="bg-[#323232] hover:bg-[gray-700] hover:text-black flex justify-center text-white py-2 px-4 rounded max-w-[200px] mx-auto">
              <AddFriendIcon />
            </button>
          )}
           {/* Display Listings */}
           {!loadingListings && listings.length > 0 && (
            <>
              <h2 className="text-2xl text-[#323232] text-center font-semibold mb-4 mt-[20px]">Available Items</h2>
              <ul className="sm:grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl-grid-cols-5 mb-[100px]">
                {listings.map((listing) => (
                  <ListingItem
                    key={listing.id}
                    id={listing.id}
                    listing={listing.data}
                    // Add any other props if needed
                  />
                ))}
              </ul>
            </>
          )}
        </div>
      ) : (
        <p>Loading user profile...</p>
      )}
    </div>
  );
}