import { useState, useEffect } from "react";
import Spinner from "../components/Spinner";
import { toast } from "react-toastify";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { v4 as uuidv4 } from "uuid";
import { addDoc, collection, serverTimestamp, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";

export default function CreateListing() {
  const navigate = useNavigate();
  const auth = getAuth();
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    images: {},
    link: "",
  });

  const {
    name,
    description,
    images,
    link,
  } = formData;

  useEffect(() => {
    const fetchServices = async () => {
      const servicesRef = collection(db, "services");
      const servicesSnapshot = await getDocs(servicesRef);
      const servicesList = servicesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setServices(servicesList);
    };

    fetchServices();
  }, []);

  const handleServiceChange = (service) => {
    setSelectedService(service);
  };

  const onChange = (e) => {
    let boolean = null;
    if (e.target.value === "true") {
      boolean = true;
    }
    if (e.target.value === "false") {
      boolean = false;
    }

    if (e.target.files) {
      // Check if the input is for images or receipts
      if (e.target.id === "images") {
        // Update images
        setFormData((prevState) => ({
          ...prevState,
          images: e.target.files,
        }));
      } else if (e.target.id === "receipts") {
        // Update receipts
        setFormData((prevState) => ({
          ...prevState,
          receipts: e.target.files,
        }));
      }
    } else {
      // For other input types, update the state as before
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: boolean ?? e.target.value,
      }));
    }
  };




  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (images.length > 5) {
      setLoading(false);
      toast.error("Maximum 5 images are allowed");
      return;
    }

    const storeImage = async (image) => {
      return new Promise((resolve, reject) => {
        const storage = getStorage();
        const filename = `${auth.currentUser.uid}-${image.name}-${uuidv4()}`;
        const storageRef = ref(storage, filename);
        const uploadTask = uploadBytesResumable(storageRef, image);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
          },
          (error) => {
            reject(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              resolve(downloadURL);
            });
          }
        );
      });
    };

    const imgUrls = await Promise.all(
      [...images].map((image) => storeImage(image))
    ).catch((error) => {
      setLoading(false);
      toast.error("Images not uploaded");
      return;
    });

    const { id, ...formDataWithoutId } = formData;
    const formDataCopy = {
      ...formData,
      imgUrls,
      timestamp: serverTimestamp(),
      userRef: auth.currentUser.uid,
    };

    delete formDataCopy.images;
    delete formDataCopy.receipts;


    await addDoc(collection(db, "portfolio"), formDataCopy);

    setLoading(false);
    toast.success("portfolio created");
    navigate(`/`);
  };


  if (loading) {
    return <Spinner />;
  }

  return (
    <main className="max-w-[1000px] px-2 mx-auto">
      <h1 className="text-3xl text-center mt-6 font-bold">Create Portfolio</h1>
      <form onSubmit={onSubmit}>
        <div className="max-w-md mx-auto">
          {/* Name input */}
          <p className="text-lg mt-6 font-semibold">Name</p>
          <input
            type="text"
            id="name"
            value={name}
            onChange={onChange}
            placeholder="Name"
            maxLength="52"
            minLength={5}
            required
            className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate mb-6"
          />
          {/* Quantity input */}
          {/* <div className="flex justify-start space-x-6">
            <div>
              <p className="text-lg font-semibold">Quantity</p>
              <input
                type="number"
                id="quantity"
                value={quantity}
                onChange={onChange}
                min="1"
                max="500"
                required
                className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-700 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 text-center"
              />
            </div>
          </div> */}

          {/* Description input */}
          <p className="text-lg font-semibold">Description</p>
          <textarea
            type="text"
            id="description"
            value={description}
            onChange={onChange}
            placeholder="Description"
            required
            className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate mb-6"
          />

          <p className="text-lg font-semibold">Website Link</p>
          <input
            type="text"
            id="link"
            value={link}
            onChange={onChange}
            placeholder="Link"
            required
            className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate mb-6"
          />

          {/* Image upload */}
          <div className="mb-6">
            <p className="text-lg font-semibold">Images</p>
            <p className="text-gray-600">The first image will be the cover (max 5)</p>
            <input
              type="file"
              id="images"
              onChange={onChange}
              accept=".jpg,.png,.jpeg"
              multiple
              required
              className="w-full px-3 py-1.5 text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:bg-white focus:border-slate-600"
            />
          </div>
          {/* Submit button */}
          <button
            type="submit"
            className="mb-6 w-full px-7 py-3 bg-[#323232] text-white font-medium text-sm uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          >
            Create Portfolio
          </button>
        </div>
      </form>
    </main>
  );
}