import { collection, getDocs, limit, orderBy, query, startAfter } from "firebase/firestore";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { db } from "../firebase";
import Spinner from "../components/Spinner";
import BlogItem from "../components/BlogItem";  // Make sure BlogItem is implemented similarly to the given blog item component structure
import SearchBar from "../components/SearchBar";
import Popup from "../components/PopupAd";

export default function OurBlogs() {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [ad, setAd] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
  
    useEffect(() => {
      const fetchAds = async () => {
        try {
          const adsCollection = collection(db, "ads");
          const adsSnapshot = await getDocs(adsCollection);
          const adsData = adsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setAd(adsData[0]);
          setShowPopup(true);
        } catch (error) {
          console.error("Error fetching ads:", error);
        }
      };
  
      fetchAds();
    }, []);
  
    const handleClosePopup = () => {
      setShowPopup(false);
    };
  
    useEffect(() => {
      const fetchBlogs = async () => {
        try {
          const blogsCollection = collection(db, "blogs");
          const blogsSnapshot = await getDocs(blogsCollection);
          const blogsData = blogsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setBlogs(blogsData);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching blogs:", error);
          toast.error("Error fetching blogs!");
        }
      };
  
      fetchBlogs();
    }, []);
  
    return (
      <div className="max-w-6xl mx-auto px-3 text-black">
        {showPopup && ad && <Popup ad={ad} onClose={handleClosePopup} />}
        <h1 className="text-3xl text-center mt-6 font-bold mb-5">My Blogs</h1>
        {loading ? (
          <Spinner />
        ) : blogs.length > 0 ? (
          <>
            <main>
              <ul className="sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3">
                {blogs.map((blog) => (
                  <BlogItem
                    key={blog.id}
                    id={blog.id}
                    blog={blog}
                  />
                ))}
              </ul>
            </main>
          </>
        ) : (
          <p>There are no current Blogs</p>
        )}
      </div>
    );
}
