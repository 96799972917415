import {
    collection,
    getDocs,
    limit,
    orderBy,
    query,
    startAfter,
    // where,
  } from "firebase/firestore";
  import { useEffect, useState } from "react";
  import { toast } from "react-toastify";
  import { db } from "../firebase";
  import Spinner from "../components/Spinner";
  import ListingItem from "../components/ListingItem";
  import SearchBar from "../components/SearchBar";
  import Popup from "../components/PopupAd"
  
  export default function Properties() {
    const [portfolio, setListings] = useState(null);
    const [loading, setLoading] = useState(true); //Is true because we want to have the loading first and when the data fetching finished, we want to set the loading to false and see the data.
    const [lastFetchedListing, setLastFetchedListing] = useState(null);
    const [ad, setAd] = useState(null);
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        const fetchAds = async () => {
            try {
                const adsCollection = collection(db, 'ads');
                const adsSnapshot = await getDocs(adsCollection);
                const adsData = adsSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setAd(adsData[0]); // Get the first ad for the popup
                setShowPopup(true); // Show the popup
            } catch (error) {
                console.error('Error fetching ads:', error);
            }
        };

        fetchAds();
    }, []);

    const handleClosePopup = () => {
        setShowPopup(false);
    };
    
    useEffect(() => {
      const fetchListings = async () => {
        try {
          const listingRef = collection(db, "portfolio");
          const q = query(
            listingRef,
            // where("offer", "==", true),
            orderBy("timestamp", "desc"),
            limit(4)
          );
          const querySnap = await getDocs(q);
  
          //Load More
          const lastVisible = querySnap.docs[querySnap.docs.length - 1];
          setLastFetchedListing(lastVisible);
  
          const portfolio = [];
          querySnap.forEach((doc) => {
            return portfolio.push({
              id: doc.id,
              data: doc.data(),
            });
          });
          setListings(portfolio);
          setLoading(false);
        } catch (error) {
          toast.error("Could not fetch listing");
        }
      };
      fetchListings();
    }, []);
  
    const onFetchMoreListings = async () => {
      try {
        const listingRef = collection(db, "portfolio");
        const q = query(
          listingRef,
        //   where("offer", "==", true),
          orderBy("timestamp", "desc"),
          startAfter(lastFetchedListing),
          limit(4)
        );
        const querySnap = await getDocs(q);
  
        //Load More
        const lastVisible = querySnap.docs[querySnap.docs.length - 1];
        setLastFetchedListing(lastVisible);
  
        const portfolio = [];
        querySnap.forEach((doc) => {
          return portfolio.push({
            id: doc.id,
            data: doc.data(),
          });
        });
        setListings(prevState=>[...prevState, ...portfolio]);
        setLoading(false);
      } catch (error) {
        toast.error("Could not fetch listing");
      }
    };
  
    return (
      <div className="max-w-6xl mx-auto px-3">
        {showPopup && ad && <Popup ad={ad} onClose={handleClosePopup} />}
        <h1 className="text-3xl text-black text-center mt-6 font-bold mb-5">My Portfolio</h1>
        {loading ? (
          <Spinner />
        ) : portfolio && portfolio.length > 0 ? (
          <>
            <main>
            {/* <SearchBar /> */}
              <ul className="sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
                {portfolio.map((listing) => (
                  <ListingItem
                    key={listing.id}
                    id={listing.id}
                    listing={listing.data}
                  />
                ))}
              </ul>
            </main>
            {lastFetchedListing && (
              <div className="flex justify-center items-center">
                <button
                  className="bg-white px-3 py-1.5 text-gray-700 border border-gray-300 mb-6 mt-6 hover:border-slate-600 rounded transition ease-in-out duration-150 mb-[100px]"
                  onClick={onFetchMoreListings}
                >
                  Load More
                </button>
              </div>
            )}
          </>
        ) : (
          <p>There are no current works</p>
        )}
      </div>
    );
  }
  